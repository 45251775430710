<template>
  <section class="bando-head">
    <h1>Agenda des Facteurs Services Experts</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">
        Agenda des FSE pour l'établissement
        <span class="yellow">LABEGE PDC1</span>
      </h2>
      <h3>
        <img src="@/assets/img/icon-zones.png" alt="Zones de vacations" />
        <span>
          Zones de vacations
          <ul>
            <!-- Class "active" sur zone sélectionnée -->
            <li>
              <a href="" @click.prevent="selectZv($event)" class="btn active">
                Toutes les zones
              </a>
            </li>
            <li v-for="zoneVacation in zonesVacations" :key="zoneVacation">
              <a
                href=""
                @click.prevent="selectZv($event, zoneVacation)"
                class="btn"
              >
                {{ zoneVacation.nom }}
              </a>
            </li>
          </ul>
        </span>
      </h3>

      <div class="bloc-calendar">
        <!-- <content-loader
          :speed="2"
          style="opacity: 0.5"
          primaryColor="#ffc928"
          secondaryColor="#ecebeb"
        >
          <path
            style="fill:#010002;"
            d="M7.685,24.819H8.28v-2.131h3.688v2.131h0.596v-2.131h3.862v2.131h0.597v-2.131h4.109v2.131h0.595    v-2.131h3.417v-0.594h-3.417v-3.861h3.417v-0.596h-3.417v-3.519h3.417v-0.594h-3.417v-2.377h-0.595v2.377h-4.109v-2.377h-0.597    v2.377h-3.862v-2.377h-0.596v2.377H8.279v-2.377H7.685v2.377H3.747v0.594h3.938v3.519H3.747v0.596h3.938v3.861H3.747v0.594h3.938    V24.819z M12.563,22.094v-3.861h3.862v3.861H12.563z M21.132,22.094h-4.109v-3.861h4.109V22.094z M21.132,14.118v3.519h-4.109    v-3.519C17.023,14.119,21.132,14.119,21.132,14.118z M16.426,14.118v3.519h-3.862v-3.519    C12.564,14.119,16.426,14.119,16.426,14.118z M8.279,14.118h3.688v3.519H8.279V14.118z M8.279,18.233h3.688v3.861H8.279V18.233z"
          />
          <path
            style="fill:#010002;"
            d="M29.207,2.504l-4.129,0.004L24.475,2.51v2.448c0,0.653-0.534,1.187-1.188,1.187h-1.388    c-0.656,0-1.188-0.533-1.188-1.187V2.514l-1.583,0.002v2.442c0,0.653-0.535,1.187-1.191,1.187h-1.388    c-0.655,0-1.188-0.533-1.188-1.187V2.517l-1.682,0.004v2.438c0,0.653-0.534,1.187-1.189,1.187h-1.389    c-0.653,0-1.188-0.533-1.188-1.187V2.525H8.181v2.434c0,0.653-0.533,1.187-1.188,1.187H5.605c-0.656,0-1.189-0.533-1.189-1.187    V2.53L0,2.534v26.153h2.09h25.06l2.087-0.006L29.207,2.504z M27.15,26.606H2.09V9.897h25.06V26.606z"
          />
          <path
            style="fill:#010002;"
            d="M5.605,5.303h1.388c0.163,0,0.296-0.133,0.296-0.297v-4.16c0-0.165-0.133-0.297-0.296-0.297H5.605    c-0.165,0-0.298,0.132-0.298,0.297v4.16C5.307,5.17,5.44,5.303,5.605,5.303z"
          />
          <path
            style="fill:#010002;"
            d="M11.101,5.303h1.389c0.164,0,0.297-0.133,0.297-0.297v-4.16c-0.001-0.165-0.134-0.297-0.298-0.297    H11.1c-0.163,0-0.296,0.132-0.296,0.297v4.16C10.805,5.17,10.938,5.303,11.101,5.303z"
          />
          <path
            style="fill:#010002;"
            d="M16.549,5.303h1.388c0.166,0,0.299-0.133,0.299-0.297v-4.16c-0.001-0.165-0.133-0.297-0.299-0.297    h-1.388c-0.164,0-0.297,0.132-0.297,0.297v4.16C16.252,5.17,16.385,5.303,16.549,5.303z"
          />
          <path
            style="fill:#010002;"
            d="M21.899,5.303h1.388c0.164,0,0.296-0.133,0.296-0.297v-4.16c0-0.165-0.132-0.297-0.296-0.297    h-1.388c-0.164,0-0.297,0.132-0.297,0.297v4.16C21.603,5.17,21.735,5.303,21.899,5.303z"
          />
        </content-loader> -->
        <div id="calendar"></div>

        <div class="fse-rattaches">
          <h3 class="align-items-center">
            <img src="@/assets/img/icon-fse.png" alt="FSE rattachés" />
            FSE rattachés
          </h3>
          <div class="d-flex" v-for="fse in fses" :key="fse">
            <div
              class="fse"
              :style="'background: ' + fse.couleur + ';'"
              :data-color="fse.couleur"
            >
              {{ fse.prenom }} {{ fse.nom }}
            </div>
            <a
              href="#"
              class="view"
              title="Afficher sur l'agenda"
              @click.prevent="toggleFse(fse)"
            >
              <i class="fal fa-calendar-alt"></i>
              <i class="fal fa-slash" v-show="!fse.visible"></i>
            </a>
          </div>
        </div>
      </div>

      <!-- BTN -->
      <!-- <div class="text-center">
        <router-link
          :to="{ name: 'VacationsEtablissement' }"
          class="btn btn-primary"
          v-if="isOperateur || isResponsable"
        >
          Gérer les vacations des FSE
        </router-link>
      </div> -->
    </section>

    <!-- Modal RDV -->
    <div
      class="modal fade modal-rdv"
      id="modal-rdv"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Fermer"
          >
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
          </button>
          <div class="modal-body">
            <h2 class="title">Bénéficiaire :</h2>

            <!-- BENEFICIAIRE -->
            <div class="bloc-shadow">
              <h3>
                {{ civilite }} {{ rdv.beneficiaire?.prenom }}
                {{ rdv.beneficiaire?.nom }}
              </h3>
              <p>
                Adresse :
                <strong>{{ rdv.beneficiaire?.adresse }}</strong>
                <br />
                Tél :
                <strong>{{ rdv.beneficiaire?.telephone_mobile }}</strong>
                <br />
                Email :
                <strong>{{ rdv.beneficiaire?.email }}</strong>
                <br />
                Référence bénéficiaire :
                <strong>{{ rdv.beneficiaire?.reference }}</strong>
              </p>
            </div>

            <h2 class="title">Rendez-vous avec le Facteur Service Expert :</h2>

            <!-- FSE -->
            <!-- <div class="fse-rattaches bloc-shadow"> -->
            <div class="bloc-fse bloc-shadow">
              <div class="infos">
                <img src="@/assets/img/icon-fse.png" alt="FSE" />
                <div>
                  <h3>{{ fse?.prenom }} {{ fse?.nom }}</h3>
                  <p>
                    Etablissement :
                    <strong>LABEGE PDC1</strong>
                    <br />
                    <!-- Zone :
                    <strong>{{ zoneVacation?.nom }}</strong> -->
                  </p>
                </div>
              </div>
              <div class="infos">
                <img src="@/assets/img/icon-calendar.png" alt="Date du rdv" />
                <div>
                  <h3>
                    {{ dateStr }} <br />
                    de {{ startTimeStr }} à {{ endTimeStr }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Annuler le RDV
            </button>
            <button type="button" class="btn btn-primary">
              Modifier le RDV
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from '@fullcalendar/core/locales/fr';
// import { ContentLoader } from "vue-content-loader";

const $ = window.$;

export default {
  name: "AgendaFSE",
  components: {
    // ContentLoader
  },
  data() {
    return {
      fses: [],
      fse: {
        nom: "",
        prenom: ""
      },
      rdv: {
        beneficiaire: {
          nom: "",
          prenom: ""
        }
      },
      zonesVacations: [],
      zoneVacation: {
        nom: ""
      },
      currentDateStr: "",
      currentDate: new Date(),
      currentDateF: new Date(),
      calendar: {},
      error: false,
      loading: false
    };
  },
  created() {
    axios
      .all([
        axios.get(process.env.VUE_APP_API + "/fse/list"),
        axios.get(process.env.VUE_APP_API + "/zone-vacation/list")
      ])
      .then(
        axios.spread((...responses) => {
          this.fses = responses[0].data;
          this.fses.forEach(f => {
            f.visible = true;
          });
          this.zonesVacations = responses[1].data;
        })
      )
      .catch(() => {
        this.$swal.fire("Une erreur s'est produite.");
      });
  },
  watch: {
    fses: function() {
      var calendarEl = document.getElementById("calendar");
      this.calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay"
        },
          locale: frLocale,
          editable: false,
          displayEventTime: true,
        selectable: false,
          hiddenDays: [0],
        businessHours: {
          // days of week. an array of zero-based day of week integers (0=Sunday)
          daysOfWeek: [1, 2, 3, 4, 5, 6], // Monday - Saturday
          startTime: "7:00", // a start time (7am in this example)
          endTime: "19:00" // an end time (7pm in this example)
        },
        // droppable: true,
        events: (info, successCallback, failureCallback) => {
          console.log(info, failureCallback);
          let events = [];
          this.fses.map(f => {
            const rdvs = [];
            f.rdvs.forEach(r => {
              if (r.etat == "O") {
                rdvs.push({
                  id: r.id,
                  title:
                    r.beneficiaire.civilite.charAt(0).toUpperCase() +
                    r.beneficiaire.civilite.slice(1) +
                    " " +
                    r.beneficiaire.nom,
                  start: r.date_start,
                  end: r.date_end,
                  color: f.couleur
                });
              }
            });
            events = events.concat(rdvs);
          });
          successCallback(events);
        },
        eventClick: info => {
          this.fses.forEach(f => {
            f.visible = true;
            f.rdvs.forEach(r => {
              if (info.event.id == r.id) {
                this.rdv = r;
                this.fse = f;
              }
            });
          });
          $("#modal-rdv").modal();
        }
      });
      this.calendar.render();
    }
  },
  methods: {
    selectZv(e, z) {
      this.zoneVacation = z;
      document.querySelectorAll("a.btn").forEach(node => {
        node.classList.remove("active");
      });
      e.srcElement.classList.add("active");
      this.calendar.getEventSources()[0].remove();
      this.calendar.addEventSource((info, successCallback, failureCallback) => {
        console.log(info, failureCallback);
        let data = this.fses.filter(f => f.visible);
        let events = [];
        data.map(f => {
          const rdvs = [];
          f.rdvs.forEach(r => {
            let b = false;
            if (z && z.communes) {
              z.communes.forEach(c => {
                if (c.cp == r.beneficiaire.adresses[0].address_line6_zipcode) {
                  b = true;
                }
              });
            } else {
              b = true;
            }
            if (b && r.etat == "O") {
              rdvs.push({
                id: r.id,
                title:
                  r.beneficiaire.civilite.charAt(0).toUpperCase() +
                  r.beneficiaire.civilite.slice(1) +
                  " " +
                  r.beneficiaire.nom,
                start: r.date_start,
                end: r.date_end,
                color: f.couleur
              });
            }
          });
          events = events.concat(rdvs);
        });
        successCallback(events);
      });
    },
    toggleFse(fse) {
      fse.visible = !fse.visible;
      this.calendar.getEventSources()[0].remove();
      this.calendar.addEventSource((info, successCallback, failureCallback) => {
        console.log(info, failureCallback);
        let data = this.fses.filter(f => f.visible);
        let events = [];
        data.map(f => {
          const rdvs = [];
          f.rdvs.forEach(r => {
            let b = false;
            if (this.zoneVacation && this.zoneVacation.communes) {
              this.zoneVacation.communes.forEach(c => {
                if (c.cp == r.beneficiaire.adresses[0].address_line6_zipcode) {
                  b = true;
                }
              });
            } else {
              b = true;
            }
            if (b && r.etat == "O") {
              rdvs.push({
                id: r.id,
                title:
                  r.beneficiaire.civilite.charAt(0).toUpperCase() +
                  r.beneficiaire.civilite.slice(1) +
                  " " +
                  r.beneficiaire.nom,
                start: r.date_start,
                end: r.date_end,
                color: f.couleur
              });
            }
          });
          events = events.concat(rdvs);
        });
        successCallback(events);
      });
    }
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isProfileLoaded",
      "isAdmin",
      "isFse",
      "isOperateur",
      "isResponsable"
    ]),
    civilite() {
      return this.rdv && this.rdv.id
        ? this.rdv.beneficiaire.civilite.charAt(0).toUpperCase() +
            this.rdv.beneficiaire.civilite.slice(1)
        : "";
    },
    dateStr() {
      if (this.rdv && this.rdv.id) {
        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric"
        };
        let dateStr = new Date(this.rdv.date_start).toLocaleDateString(
          "fr-FR",
          options
        );
        dateStr = dateStr.charAt(0).toUpperCase() + dateStr.slice(1);
        return dateStr;
      } else {
        return "";
      }
    },
    startTimeStr() {
      if (this.rdv && this.rdv.id) {
        const options = { hour: "2-digit", minute: "2-digit" };
        return new Date(this.rdv.date_start).toLocaleTimeString(
          "fr-FR",
          options
        );
      } else {
        return "";
      }
    },
    endTimeStr() {
      if (this.rdv && this.rdv.id) {
        const options = { hour: "2-digit", minute: "2-digit" };
        return new Date(
          new Date(this.rdv.date_end).getTime()
        ).toLocaleTimeString("fr-FR", options);
      } else {
        return "";
      }
    }
  }
};
</script>

<style lang="scss">
.fc-daygrid-event-harness,
.fc-timegrid-event-harness {
  cursor: pointer;
}
.fc td.fc-sat, .fc td.fc-day-sat {
  background-color: rgb(255, 255, 255);
}
</style>

<style scoped lang="scss">
.content {
  h3 {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
    width: 100%;

    @media screen and (min-width: 992px) {
      align-items: center;
    }

    img {
      margin-right: 1.5rem;
    }

    ul {
      display: flex;
      margin: 0.5rem 0 0;
      padding: 0;
      flex-wrap: wrap;

      li {
        list-style: none;
        margin: 0 1rem 0.5rem 0;
      }

      .btn {
        background: var(--grey);
        color: white;
        font-size: 1.4rem;
        padding: 0.75rem 3rem;

        &:hover,
        &.active {
          background: var(--navy);
        }
      }
    }
  }

  .bloc-calendar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (min-width: 992px) {
      flex-direction: row;
    }

    #calendar {
      order: 2;

      @media screen and (min-width: 992px) {
        order: 1;
        min-width: 75%;
        flex-basis: 75%;
        padding-right: 6rem;
      }
    }

    .fse-rattaches {
      margin-bottom: 2rem;
      order: 1;

      @media screen and (min-width: 992px) {
        min-width: 25%;
        flex-basis: 25%;
        margin-bottom: 0;
        order: 2;
      }

      h4 {
        background: var(--navy);
        border-radius: 0.5rem;
        color: white;
        font-size: 1.4rem;
        margin: 0 0 3rem;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
      }

      .d-flex {
        width: 100%;
      }

      .fse {
        margin-bottom: 1rem;
        min-width: 70%;
      }

      a {
        color: black;

        &:hover {
          text-decoration: none;
        }

        &.view {
          margin: 0.5rem 0 0 1rem;
          position: relative;

          &:hover {
            color: var(--yellow);
          }

          i {
            font-size: 2.4rem;

            &.fa-slash {
              position: absolute;
              top: 0;
              left: -3px;
            }
          }
        }
      }
    }
  }
}

.modal {
  .close {
    top: -1.5rem;
    right: -1.5rem;
  }

  &-body {
    padding: 5rem 5rem 2rem;
  }

  .bloc-shadow {
    margin-bottom: 3.5rem;
    padding: 1.5rem 2rem;

    h3 {
      margin-bottom: 0;
    }

    p {
      font-size: 1.4rem;
      margin: 0;
    }
  }

  .bloc-fse {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;

    .infos {
      display: flex;
      align-items: flex-start;
      flex: 1 1 auto !important;

      &:first-child {
        min-width: 55%;
        flex-basis: 55%;
        padding-right: 3rem;
      }

      &:last-child {
        min-width: 45%;
        flex-basis: 45%;
      }

      img {
        margin-right: 2.5rem;
      }

      div {
        padding-top: 1rem;
      }
    }
  }
}
</style>
